<template>
  <div class="main-body">
    <div class="page-attention">
      <p>请先到百度资源管理平台认证站点,认证后获取api接口地址,点击前往<a href="https://ziyuan.baidu.com/site/siteadd#/" target="_blank">百度站点管理</a></p>
      <p>
        请在输入框中填写当前站点的链接；非当前站点的链接提交将无效；
      </p>
      <p>
        您每次最多可提交20条链接，每行一条；
      </p>
      <p>
        仅支持页面对应链接的提交，不支持sitemap形式的文件提交
      </p>
      <p>
        如果需要提交非验证本站链接，请登录<a href="https://ziyuan.baidu.com/linksubmit/url" target="_blank">百度资源平台</a>
      </p>
    </div>
    <div class="card-item">
      <a-form ref="formRef" :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol" @finish="onSubmit" @finishFailed="onFinishFailed">
        <div class="order-attention">
          <p>百度API接口推送</p>
        </div>
        <a-form-item label="接口地址" name="api_url" :rules="[{ required: true, message: '请输入推送接口地址!' }]">
          <a-input v-model:value="formState.api_url" placeholder="请输入推送接口地址" />
        </a-form-item>
        <a-form-item label="推送接口" name="urls">
          <a-textarea style="height:200px" v-model:value="formState.urls" placeholder="请输入需要推送的地址,每行一个；
示例如下：
http://www.example.com/mip/1.html
http://www.example.com/mip/2.htm
http://www.example.com/mip/3.php" />
        </a-form-item>

        <a-form-item :wrapper-col="{ span: 8, offset: 3 }">
          <a-button type="primary" html-type="submit">保存</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { client_get_config } from "@/views/client/api";
import { native_save_seo } from "@/views/client/nativeApi";
import { message } from "ant-design-vue";
import { useRoute } from "vue-router";
export default defineComponent({
  components: {},
  setup() {
    //获取当前应用ID
    const route = useRoute();
    const appid = route.query.appid;

    const formRef = ref();
    const formState = reactive({
      appid: appid,
      api_url:
        "http://data.zz.baidu.com/urls?site=https://www.5shopcloud.com&token=IzholqS18lLxkoYC",
      urls: "",
    });

    // console.log(appid);
    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          native_save_seo(formState).then((res) => {
            if (res.status == 200) {
              message.success(res.message);
              getInfo();
            }
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };
    const getInfo = () => {
      client_get_config({
        appid: appid,
      }).then((res) => {
        if (res.status == 200) {
          formState.api_url = res.data.setting.api_url
            ? res.data.setting.api_url
            : "";
        }
      });
    };
    onMounted(() => {
      getInfo();
    });
    return {
      formRef,
      formState,
      onSubmit,
      onFinishFailed,
      labelCol: {
        span: 3,
      },
      wrapperCol: {
        span: 8,
      },
    };
  },
});
</script>